export default {
  button: {
    primary: {
      base: "text-white bg-fuchsia-800 border border-transparent",
      active:
        "active:bg-fuchsia-800 hover:bg-fuchsia-700 focus:ring focus:ring-fuchsia-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
  },
  // Input
  input: {
    checkbox:
      "text-fuchsia-600 form-checkbox focus:border-fuchsia-400 focus:outline-none focus:ring focus:ring-fuchsia-300 focus:ring-offset-0 rounded dark:focus:ring-gray-300",
  },
  // Backdrop
  backdrop: {
    base: "absolute h-full left-0 top-0 w-full z-40 bg-black bg-opacity-75 flex items-end sm:items-center sm:justify-center overflow-auto min-h-screen",
  },
  modal: {
    base: "w-full  px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  },
  // ModalBody
  modalBody: {
    base: "mb-6 text-sm text-gray-700 dark:text-gray-400",
  },
  // ModalFooter
  modalFooter: {
    base: "flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800",
  },
  // ModalHeader
  modalHeader: {
    base: "mt-4 mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300",
  },
};
