// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? {
        apiKey: "AIzaSyBWTWFa-CRQtVH9s3j-WvBXWCbYcG9x6fc",
        authDomain: "music-coast-production.firebaseapp.com",
        projectId: "music-coast-production",
        storageBucket: "music-coast-production.appspot.com",
        messagingSenderId: "979803480314",
        appId: "1:979803480314:web:1f7788fd9927b2cda16943",
        measurementId: "G-DHBLXJ1ESZ",
      }
    : {
        apiKey: "AIzaSyATW2DPckCiK8oMUDXQfWxIOTf1hOUkWXw",
        authDomain: "music-coast-69b3b.firebaseapp.com",
        projectId: "music-coast-69b3b",
        storageBucket: "music-coast-69b3b.appspot.com",
        messagingSenderId: "187419715388",
        appId: "1:187419715388:web:a7c54f4cecfa5bd55ae039",
        measurementId: "G-C1CNMLJSFG",
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
